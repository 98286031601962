<template>
  <div class="before-after-selector">
    <BeforeAfterBlock
      v-if="
        typeof beforeAfterContent === 'object' && beforeAfterContent.content
      "
      :before-image="beforeAfterContent.content.beforeImage"
      :after-image="beforeAfterContent.content.afterImage"
      :image-position="beforeAfterContent.content.imagePosition"
      :product-name="beforeAfterContent.content.productName"
      :product-description="beforeAfterContent.content.productDescription"
      :centra-product-id="beforeAfterContent.content.centraProductId"
      :mobile-padding="mobilePadding"
      :mobile-margin="mobileMargin"
      :desktop-padding="desktopPadding"
      :desktop-margin="desktopMargin"
      :desktop-indent="desktopIndent"
      :force-mobile-layout="forceMobileLayout"
      :ctas="ctas"
      :align-cta="alignCta"
      :mobile-hide-cta="mobileHideCta"
      :desktop-hide-cta="desktopHideCta"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'BeforeAfterSelector',
  props: {
    storySelector: {
      type: [Object, String],
      default: () => {},
    },
    mobilePadding: {
      type: String,
      required: false,
      default: '',
    },
    mobileMargin: {
      type: String,
      required: false,
      default: '',
    },
    desktopPadding: {
      type: String,
      required: false,
      default: '',
    },
    desktopMargin: {
      type: String,
      required: false,
      default: '',
    },
    desktopIndent: {
      type: String,
      required: false,
      default: '',
    },
    forceMobileLayout: {
      type: Boolean,
      default: false,
    },
    ctas: {
      type: Array,
      default: () => [],
    },
    alignCta: {
      type: String,
      default: 'flex-start',
    },
    mobileHideCta: {
      type: Boolean,
      required: false,
      default: false,
    },
    desktopHideCta: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      beforeAfterData: {},
    }
  },
  computed: {
    beforeAfterContent() {
      if (typeof this.storySelector === 'string') {
        return this.beforeAfterData
      } else {
        return this.storySelector
      }
    },
  },
  watch: {
    storySelector: {
      immediate: true,
      async handler(newValue) {
        if (newValue && typeof newValue === 'string' && this.beforeAfterData) {
          await this.getBeforeAfterContent(newValue)
        }
      },
    },
  },

  methods: {
    ...mapActions({
      fetchStory: 'storyblok/fetchStory',
    }),
    /**
     * fetches before after story by uuid, this is only used to make storyblok preview work,
     * When ran on actual site the route handler will resolve relations and put the story directly into the storySelector prop
     */
    getBeforeAfterContent(id) {
      const params = {
        find_by: 'uuid',
      }

      return this.fetchStory({ id, params })
        .then((response) => {
          this.beforeAfterData = response
        })
        .catch((e) => {
          console.error('Could not fetch Storyblok story: ', e)
        })
    },
  },
}
// :review-image="storySelector.content.reviewImage"
// :review-author="storySelector.content.reviewAuthor"
// :review-text="storySelector.content.reviewText"
</script>
<style lang="scss" scoped>
.before-after-selector {
  width: 100%;
}
</style>
